import React, { useEffect, useState } from "react";
import { LoginCallback, useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { Outlet, Route, Routes } from "react-router";
import { toRelativeUrl } from "@okta/okta-auth-js";

type Props = {
  children: JSX.Element | JSX.Element[];
};

function AuthenticationWrapper(props: Props) {
  const { oktaAuth, authState } = useOktaAuth();
  const [authDone, setAuthDone] = useState(false);

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      );
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    } else {
      const accessToken = JSON.parse(
        localStorage.getItem("sensing-okta-token") || ""
      )?.accessToken?.accessToken;

      if (accessToken) {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${accessToken}`;

        setAuthDone(true);
      }
    }
  }, [oktaAuth, authState, authState?.isAuthenticated]);

  if (!authDone) {
    return (
      <Routes>
        <Route path="implicit/callback" element={<LoginCallback />} />
        <Route path="*" element={<Outlet />} />
      </Routes>
    );
  }

  return <div className="rts-curation">{props.children}</div>;
}

export default AuthenticationWrapper;
