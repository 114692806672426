import React from "react";
import "./SubmissionFieldContainer.scss";

export type FormMode = "submit" | "submitted" | "resubmit" | "review" | "view";
export type FieldMode = "edit" | "readonly";

export type Props = {
  className?: string;
  fieldName: string;
  mode: FormMode;
  children: JSX.Element | JSX.Element[] | string | null;
};

const namespace = "curation-submission-field-container";

export const SubmissionFieldContainer = (props: Props) => {
  return (
    <div className={namespace}>
      <div className={`${namespace}-header`}>{props.fieldName} </div>
      <div className={props.className}>{props.children}</div>
    </div>
  );
};

export default SubmissionFieldContainer;
