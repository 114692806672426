import * as React from "react";

type Props = {
  fill?: string;
  height?: number;
  width?: number;
  className?: string;
};

//default to 14

export const Calendar = (props: Props) => {
  const { width = 16, height = 16, className, fill = "currentColor" } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6667 1.75004H11.0833V0.583374H9.91666V1.75004H4.08332V0.583374H2.91666V1.75004H2.33332C1.69166 1.75004 1.16666 2.27504 1.16666 2.91671V12.25C1.16666 12.8917 1.69166 13.4167 2.33332 13.4167H11.6667C12.3083 13.4167 12.8333 12.8917 12.8333 12.25V2.91671C12.8333 2.27504 12.3083 1.75004 11.6667 1.75004ZM11.6667 12.25H2.33332V5.83337H11.6667V12.25ZM2.33332 4.66671H11.6667V2.91671H2.33332V4.66671Z"
        fill={fill}
      />
    </svg>
  );
};

export default Calendar;
