import React, { useMemo } from "react";
import "./Input.scss";
import { SubmissionField } from "api";
import SubmissionFieldGrayBox from "../../field-gray-box/SubmissionFieldGrayBox";
import SubmissionFieldContainer, {
  FieldMode,
  FormMode
} from "../../field-container/SubmissionFieldContainer";
import SubmissionFieldEditContainer from "../../field-edit-container/SubmissionFieldEditContainer";

const namespace = "curation-field-input";

type Props = {
  formMode: FormMode;
  fieldMode: FieldMode;
  label: string;
  data: SubmissionField;
  onChange: (val: string) => void;
  isDisabled: boolean;
  isRequired: boolean;
};

const Input = (props: Props) => {
  const cachedSubmittedValue = useMemo(
    () => props.data.submittedValue,
    [props.formMode] // eslint-disable-line react-hooks/exhaustive-deps
  );

  let value: string | null;
  switch (props.formMode) {
    case "submit":
    case "resubmit":
      value = props.data.submittedValue;
      break;
    case "review":
      value = props.data.finalValue;
      break;
    case "view":
    case "submitted":
      value = "";
      break;
  }

  return (
    <SubmissionFieldContainer
      className={namespace}
      mode={props.formMode}
      fieldName={props.label}
    >
      <SubmissionFieldGrayBox
        fieldName={props.label}
        liveValue={props.data.liveValue}
        submittedValue={
          props.formMode === "resubmit"
            ? cachedSubmittedValue
            : props.data.submittedValue
        }
        finalValue={props.data.finalValue}
        mode={props.formMode}
      />
      <SubmissionFieldEditContainer
        label={props.label}
        description="Any edits below will replace the submission"
        submissionField={props.data}
        formMode={props.formMode}
        fieldMode={props.fieldMode}
        isRequired={props.isRequired}
      >
        <input
          value={value || ""}
          placeholder="New data"
          onChange={e => props.onChange(e.target.value)}
          disabled={props.isDisabled}
        />
      </SubmissionFieldEditContainer>
    </SubmissionFieldContainer>
  );
};

export default Input;
