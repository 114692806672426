import React from "react";

type Props = {
  fill?: string;
  width?: number;
  height?: number;
  className?: string;
};

export const MinusIconAlt = (props: Props) => {
  const { fill = "currentColor", width = 16, height = 16, className } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7.5" cy="7.5" r="6.5" stroke={fill} />
      <rect x="5" y="7" width="5" height="1.33333" rx="0.666667" fill={fill} />
    </svg>
  );
};

export default MinusIconAlt;
