import React from "react";
import classNames from "classnames";
import { Checkbox as CheckboxAnt, CheckboxProps } from "antd";
import "./Checkbox.scss";

const namespace = "curation-checkbox";

export type Props = CheckboxProps;

export const Checkbox = (props: Props): JSX.Element => {
  const className = classNames(namespace, props.className);

  return <CheckboxAnt {...props} className={className} />;
};

export default Checkbox;
