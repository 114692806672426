import React from "react";
import "./SubmissionFormReviewedMessage.scss";
import { Submission } from "api";
import dayjs from "dayjs";

const namespace = "curation-submission-form-reviewed-message";

type Props = {
  submission: Submission | undefined;
};

export const SubmissionFormReviewedMessage = (props: Props) => {
  if (!props.submission?.reviewedDate) {
    return <></>;
  }

  const reviewedDate = dayjs.utc(props.submission.reviewedDate).tz();

  const hasChanges: boolean = props.submission.fieldEntries.some(
    s => s.finalValue !== s.submittedValue
  );

  let resolutionText = "";
  let additionalText = <></>;
  switch (props.submission.status.status) {
    case "approved":
      resolutionText = hasChanges ? "edited and confirmed" : "accepted";
      additionalText = (
        <>
          Please see below for a summary of prior, submitted, and final item
          contents.
        </>
      );
      break;
    case "rejected":
      resolutionText = "rejected";
      additionalText = (
        <>
          <label>Rejection Reason: </label>
          <span>{props.submission.rejectReason}</span>
        </>
      );
      break;
    case "in review":
      resolutionText = "in review";
      additionalText = (
        <>
          This item is currently being reviewed. Please see below for a summary
          of prior and submitted item contents.
        </>
      );
      break;
  }

  return (
    <div className={namespace}>
      <div className={`${namespace}-time-text`}>
        This item&apos;s updates were {resolutionText} on{" "}
        {reviewedDate.format("MMM D [at] h:mm A")}.
      </div>
      <div className={`${namespace}-generic-text`}>{additionalText}</div>
    </div>
  );
};

export default SubmissionFormReviewedMessage;
