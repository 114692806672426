import React from "react";

type Props = {
  width: number;
  height: number;
  className?: string;
  onClick?: () => void;
};

export const RightArrowIcon = (props: Props) => {
  const { width, height, className, onClick } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M4.72209 2.10104C6.28428 1.05722 8.1209 0.500056 9.99972 0.5C12.5184 0.502956 14.9332 1.50482 16.7142 3.28584C18.4952 5.06691 19.4971 7.48175 19.5 10.0006C19.4999 11.8793 18.9427 13.7158 17.899 15.2779C16.8551 16.8402 15.3714 18.0578 13.6355 18.7769C11.8996 19.4959 9.98947 19.684 8.14665 19.3175C6.30383 18.9509 4.61109 18.0461 3.28249 16.7175C1.95389 15.3889 1.0491 13.6962 0.682544 11.8534C0.315985 10.0105 0.504116 8.1004 1.22315 6.36451C1.94218 4.62861 3.15982 3.14491 4.72209 2.10104Z"
        fill="white"
        stroke="#888888"
      />
      <path
        d="M8.2902 5.14092C8.10433 5.32828 8 5.58173 8 5.84592C8 6.11011 8.10433 6.36356 8.2902 6.55092L11.823 10.1409L8.2902 13.6809C8.10433 13.8683 8 14.1217 8 14.3859C8 14.6501 8.10433 14.9036 8.2902 15.0909C8.38297 15.1846 8.49335 15.259 8.61496 15.3098C8.73657 15.3606 8.86701 15.3867 8.99875 15.3867C9.1305 15.3867 9.26094 15.3606 9.38255 15.3098C9.50416 15.259 9.61453 15.1846 9.70731 15.0909L13.9387 10.8509C14.0322 10.758 14.1065 10.6474 14.1571 10.5255C14.2078 10.4036 14.2339 10.2729 14.2339 10.1409C14.2339 10.0089 14.2078 9.8782 14.1571 9.75634C14.1065 9.63448 14.0322 9.52388 13.9387 9.43092L9.70731 5.14092C9.61453 5.04719 9.50416 4.9728 9.38255 4.92203C9.26094 4.87126 9.1305 4.84512 8.99875 4.84512C8.86701 4.84512 8.73657 4.87126 8.61496 4.92203C8.49335 4.9728 8.38297 5.04719 8.2902 5.14092Z"
        fill="#555555"
      />
    </svg>
  );
};

export default RightArrowIcon;
