import React, { useEffect, useState } from "react";
import "./SubmissionFormSubmit.scss";
import { Submission } from "api";
import produce from "immer";
import { Loader } from "@gitlab-rtsensing/component-library";
import SubmissionFormHeader from "../common/header/SubmissionFormHeader";
import FieldMapper from "../common/FieldMapper";
import SubmissionFormSubmitRadioButtons, {
  SubmitMode
} from "./radio-buttons/SubmissionFormSubmitRadioButtons";
import { FormMode } from "../common/field-container/SubmissionFieldContainer";
import { MutationStatus } from "@tanstack/react-query";
import ErrorBox from "common-components/error-box/error-box";
import SubmissionFormSubmitFooterButtons from "./footer-buttons/SubmissionFormSubmitFooterButtons";
import useSubmissionFormValidation from "../common/useSubmissionFormValidation";

const namespace = "curation-submission-form-submit";

type Props = {
  formMode: FormMode;
  setFormMode: React.Dispatch<React.SetStateAction<FormMode>>;
  submission: Submission | undefined;
  onSubmit: (submission: Submission) => void;
  status: MutationStatus;
  isFirstStepSkippable: boolean;
};

export const SubmissionFormSubmit = (props: Props) => {
  const [submission, setSubmission] = useState<Submission>();
  const [submitMode, setSubmitMode] = useState<SubmitMode>();
  const [errors, setErrors] = useState<string[] | undefined>(undefined);
  const { registerValidation, triggerValidation } =
    useSubmissionFormValidation();
  const [skipApproval, setSkipApproval] = useState(false)

  useEffect(() => {
    if (!props.submission) {
      return;
    }

    //sort fields
    const newSubmission = produce(props.submission, draftState => {
      draftState.skipNextApproval = skipApproval;
      draftState.fieldEntries = draftState.fieldEntries.sort((fe1, fe2) => {
        const order1 = draftState?.form.fields.find(
          f => f.id === fe1.fieldId
        )?.formOrder;

        const order2 = draftState?.form.fields.find(
          f => f.id === fe2.fieldId
        )?.formOrder;

        if (order1 === undefined || order2 === undefined) {
          return 0;
        }

        return order1 - order2;
      });

      if (submission && submission.fieldEntries && submission.fieldEntries.length > 0) {
        for (let i = 0; i < submission.fieldEntries.length; i++) {

          draftState.fieldEntries[i].submittedValue = submission.fieldEntries[i].submittedValue;

        }
      }
    });

    setSubmission(newSubmission);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.submission, skipApproval]);



  useEffect(() => {
    if (props.formMode === "submitted") {
      //only triggers directly after submission
      setSubmitMode(undefined);
    }
  }, [props.formMode]);

  const onChangeFormMode = (submitMode: SubmitMode) => {
    if (!submission) {
      return;
    }

    const newForm = produce(submission, draftState => {
      draftState.skipNextApproval = skipApproval;

      for (let i = 0; i < draftState.fieldEntries.length; i++) {
        if (draftState.status.status === "created") {
          draftState.fieldEntries[i].submittedValue =
            draftState.fieldEntries[i].liveValue;
        }
      }
    });

    setSubmission(newForm);

    setSubmitMode(submitMode);
  };

  const onChangeField = (index: number, value: string) => {
    if (!submission) {
      return;
    }

    const newForm = produce(submission, draftState => {
      draftState.fieldEntries[index].submittedValue = value;
      draftState.skipNextApproval = skipApproval;

    });

    setSubmission(newForm);
  };

  const onSubmit = (submission: Submission) => {
    const formErrors = triggerValidation();
    setErrors(formErrors);

    if (formErrors.length === 0) {
      props.onSubmit(submission);
    }
  };

  const onCancel = () => {
    props.setFormMode("submitted");
    setSubmission(props.submission);
    setErrors(undefined);
  };

  return (
    <div className={namespace}>
      <SubmissionFormHeader formMode={props.formMode} submission={submission} />
      <div className={`${namespace}-container`}>
        <Loader status={props.status}>
          <ErrorBox errors={errors} status={props.status} />
          <SubmissionFormSubmitRadioButtons
            formMode={props.formMode}
            submitMode={submitMode}
            submission={submission}
            onChange={onChangeFormMode}
            isDisabled={props.status === "loading"}
            isFirstStepSkippable={props.isFirstStepSkippable}
            skipApproval={skipApproval}
            onChangeSkipApproval={setSkipApproval}
          />

          <>
            {submission?.fieldEntries.map((f, i) => (
              <FieldMapper
                key={i}
                fields={submission.form.fields}
                field={f}
                index={i}
                onChange={onChangeField}
                formMode={props.formMode}
                fieldMode={submitMode || "readonly"}
                isDisabled={props.status === "loading"}
                registerValidation={registerValidation}
              />
            ))}
          </>
        </Loader>
        <SubmissionFormSubmitFooterButtons
          formMode={props.formMode}
          setFormMode={props.setFormMode}
          submitMode={submitMode}
          status={props.status}
          submission={submission}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  );
};

export default SubmissionFormSubmit;
