import * as React from "react";

type Props = {
  fill?: string;
  height?: number;
  width?: number;
  className?: string;
};

export const PlusIcon = (props: Props) => {
  const { width = 11, height = 10, className, fill = "currentColor" } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.5835 4.41671H6.0835V0.916707C6.0835 0.761998 6.02204 0.613625 5.91264 0.504229C5.80325 0.394832 5.65487 0.333374 5.50016 0.333374C5.34545 0.333374 5.19708 0.394832 5.08768 0.504229C4.97829 0.613625 4.91683 0.761998 4.91683 0.916707V4.41671H1.41683C1.26212 4.41671 1.11375 4.47817 1.00435 4.58756C0.894954 4.69696 0.833496 4.84533 0.833496 5.00004C0.833496 5.15475 0.894954 5.30312 1.00435 5.41252C1.11375 5.52192 1.26212 5.58337 1.41683 5.58337H4.91683V9.08337C4.91683 9.23808 4.97829 9.38646 5.08768 9.49585C5.19708 9.60525 5.34545 9.66671 5.50016 9.66671C5.65487 9.66671 5.80325 9.60525 5.91264 9.49585C6.02204 9.38646 6.0835 9.23808 6.0835 9.08337V5.58337H9.5835C9.73821 5.58337 9.88658 5.52192 9.99598 5.41252C10.1054 5.30312 10.1668 5.15475 10.1668 5.00004C10.1668 4.84533 10.1054 4.69696 9.99598 4.58756C9.88658 4.47817 9.73821 4.41671 9.5835 4.41671Z"
        fill={fill}
      />
    </svg>
  );
};

export default PlusIcon;
