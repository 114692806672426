import React from "react";

type Props = {
  fill?: string;
  width?: number;
  height?: number;
  className?: string;
};

export const CheckIcon = (props: Props) => {
  const { fill = "currentColor", width = 16, height = 16, className } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="1 1 16 16"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.99"
        d="M7.73437 12.0703C7.63587 12.0704 7.53832 12.0511 7.44732 12.0134C7.35632 11.9756 7.27367 11.9203 7.20412 11.8506L5.09475 9.74121C4.95557 9.60027 4.8778 9.41 4.87842 9.21193C4.87904 9.01386 4.958 8.82407 5.09805 8.68401C5.23811 8.54395 5.42789 8.46499 5.62596 8.46436C5.82403 8.46373 6.01431 8.54149 6.15525 8.68066L7.73435 10.2598L11.8447 6.14941C11.9857 6.01024 12.176 5.93248 12.374 5.93311C12.5721 5.93374 12.7619 6.0127 12.9019 6.15276C13.042 6.29282 13.121 6.48261 13.1216 6.68068C13.1222 6.87875 13.0444 7.06902 12.9052 7.20996L8.26462 11.8506C8.19507 11.9203 8.11242 11.9757 8.02143 12.0134C7.93043 12.0511 7.83288 12.0704 7.73437 12.0703Z"
        fill="white"
      />
      <path
        d="M9 1.5C7.51664 1.5 6.0666 1.93987 4.83323 2.76398C3.59986 3.58809 2.63856 4.75943 2.07091 6.12987C1.50325 7.50032 1.35472 9.00832 1.64411 10.4632C1.9335 11.918 2.64781 13.2544 3.6967 14.3033C4.7456 15.3522 6.08197 16.0665 7.53683 16.3559C8.99168 16.6453 10.4997 16.4968 11.8701 15.9291C13.2406 15.3614 14.4119 14.4001 15.236 13.1668C16.0601 11.9334 16.5 10.4834 16.5 9C16.4977 7.01157 15.7068 5.10524 14.3008 3.69921C12.8948 2.29318 10.9884 1.50227 9 1.5ZM12.9053 7.20996L8.26463 11.8506C8.19501 11.9202 8.11234 11.9755 8.02135 12.0132C7.93037 12.0509 7.83284 12.0703 7.73436 12.0703C7.63587 12.0703 7.53835 12.0509 7.44736 12.0132C7.35637 11.9755 7.27371 11.9202 7.20408 11.8506L5.09475 9.74121C4.95558 9.60028 4.87781 9.41 4.87843 9.21193C4.87905 9.01386 4.958 8.82408 5.09806 8.68401C5.23811 8.54395 5.42789 8.46499 5.62597 8.46436C5.82404 8.46373 6.01431 8.54149 6.15525 8.68067L7.73436 10.2598L11.8448 6.14942C11.9857 6.01024 12.176 5.93248 12.374 5.93311C12.5721 5.93374 12.7619 6.0127 12.902 6.15276C13.042 6.29283 13.121 6.48261 13.1216 6.68068C13.1222 6.87875 13.0444 7.06903 12.9053 7.20996Z"
        fill={fill}
      />
    </svg>
  );
};

export default CheckIcon;
