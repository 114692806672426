import React, { useMemo } from "react";
import { FormField, SubmissionField } from "api";
import DisplayText from "./fields/display-text/DisplayText";
import Input from "./fields/input/Input";
import TextArea from "./fields/text-area/TextArea";
import ColorStatus from "./fields/color-status/ColorStatus";
import LinksInput from "./fields/links-input/LinksInput";
import {
  FieldMode,
  FormMode
} from "./field-container/SubmissionFieldContainer";

type Props = {
  field: SubmissionField;
  fields: FormField[];
  index: number;
  onChange: (index: number, value: string) => void;
  formMode: FormMode;
  fieldMode: FieldMode;
  isDisabled: boolean;
  registerValidation: (id: number, getErrors: () => string[]) => () => void;
};

export const FieldMapper = (props: Props) => {
  const formField = useMemo(() => {
    return props.fields.find(f => f.id === props.field.fieldId);
  }, [props.field, props.fields]);

  if (!formField) {
    return <></>;
  }

  //TODO JCG: implement validation logic for other field types
  switch (formField.control.type) {
    case "input":
      return (
        <Input
          key={props.index}
          label={formField.label}
          data={props.field}
          formMode={props.formMode}
          fieldMode={props.fieldMode}
          onChange={val => props.onChange(props.index, val)}
          isDisabled={props.isDisabled}
          isRequired={formField.required}
        />
      );
    case "textarea":
      return (
        <TextArea
          key={props.index}
          label={formField.label}
          data={props.field}
          formMode={props.formMode}
          fieldMode={props.fieldMode}
          onChange={val => props.onChange(props.index, val)}
          isDisabled={props.isDisabled}
          isRequired={formField.required}
        />
      );
    case "color status":
      return (
        <ColorStatus
          key={props.index}
          label={formField.label}
          data={props.field}
          formMode={props.formMode}
          fieldMode={props.fieldMode}
          onChange={val => props.onChange(props.index, val)}
          isDisabled={props.isDisabled}
          isRequired={formField.required}
        />
      );
    case "links":
      return (
        <LinksInput
          key={props.index}
          label={formField.label}
          data={props.field}
          formMode={props.formMode}
          fieldMode={props.fieldMode}
          onChange={val => props.onChange(props.index, val)}
          isDisabled={props.isDisabled}
          isRequired={formField.required}
          registerValidation={props.registerValidation}
        />
      );
    default:
      return (
        <DisplayText
          key={props.index}
          label={formField.label}
          data={props.field}
          formMode={props.formMode}
        />
      );
  }
};

export default FieldMapper;
