import { useEffect, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { getSubmission, Submission } from "api";
import pages from "pages/pages";

export const useSubmissionLocation = (path: string) => {
  const location = useLocation();
  const navigate = useNavigate();

  const id = useMemo<string>(() => {
    const matchObj = matchPath(path, location.pathname);

    return matchObj?.params.submissionId || "";
  }, [path, location]);

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const { status, data, refetch } = useQuery<Submission>(
    ["submission", id],
    () => getSubmission(id),
    {
      enabled: !!id
    }
  );

  useEffect(() => {
    if (status === "error") {
      navigate(pages.notfound.go());
    }
  }, [status, navigate]);

  return {
    id: id,
    status: status,
    data: data,
    refetch: refetch
  };
};
