import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Button } from "@gitlab-rtsensing/component-library";
import type { Props as ButtonProps } from "@gitlab-rtsensing/component-library/lib/components/elements/button/index";
import "./ApiButton.scss";
import { MutationStatus } from "@tanstack/react-query";
import StatusToast from "common-components/status-toast/StatusToast";

const namespace = "curation-api-button";

export type Props = {
  status: MutationStatus;
  midCallLabel: string;
  showStatusToast?: boolean;
  successMsg?: string;
  errorMsg?: string;
} & ButtonProps;

export const ApiButton = (props: Props): JSX.Element => {
  const className = classNames(`${namespace}-item`, props.className);
  const [prevStatus, setPrevStatus] = useState(props.status);
  const [toastMode, setToastMode] = useState<MutationStatus | undefined>(
    undefined
  );

  let label = props.label;
  let disabled = props.disabled;

  if (props.status === "loading") {
    label = props.midCallLabel;
    disabled = true;
  }

  useEffect(() => {
    if (!props.showStatusToast || prevStatus === props.status) {
      return;
    }

    setPrevStatus(props.status);

    if (props.status === "success" || props.status === "error") {
      setToastMode(props.status);

      setTimeout(() => {
        setToastMode(undefined);
      }, 2000);
    }
  }, [props.showStatusToast, props.status]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={namespace}>
      <Button
        {...props}
        label={label}
        className={className}
        disabled={disabled}
      />
      <StatusToast
        show={toastMode}
        messages={{ success: props.successMsg, error: props.errorMsg }}
        className={`${namespace}-toast`}
      />
    </div>
  );
};

export default ApiButton;
