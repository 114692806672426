import React, { useMemo } from "react";
import "./ColorStatus.scss";
import StatusIcon from "common-components/status-icon/StatusIcon";
import { SubmissionField, ColorStatus as ColorStatusType } from "api";
import StatusSelector from "./color-status-selector/ColorStatusSelector";
import SubmissionFieldContainer, {
  FieldMode,
  FormMode
} from "../../field-container/SubmissionFieldContainer";
import SubmissionFieldGrayBox from "../../field-gray-box/SubmissionFieldGrayBox";
import SubmissionFieldEditContainer from "../../field-edit-container/SubmissionFieldEditContainer";

const namespace = "curation-field-color-status";

type Props = {
  formMode: FormMode;
  fieldMode: FieldMode;
  label: string;
  data: SubmissionField;
  onChange: (val: string) => void;
  isDisabled: boolean;
  isRequired: boolean;
};

const Status = (props: Props) => {
  const cachedSubmittedValue = useMemo(
    () => (
      <span>
        Status is{" "}
        <StatusIcon status={props.data.submittedValue as ColorStatusType} />
      </span>
    ),
    [props.formMode] // eslint-disable-line react-hooks/exhaustive-deps
  );

  let value: string | null;
  switch (props.formMode) {
    case "submit":
    case "resubmit":
      value = props.data.submittedValue;
      break;
    case "review":
      value = props.data.finalValue;
      break;
    case "view":
    case "submitted":
      value = "";
      break;
  }

  return (
    <SubmissionFieldContainer
      className={namespace}
      mode={props.formMode}
      fieldName={props.label}
    >
      <SubmissionFieldGrayBox
        fieldName={props.label}
        liveValue={
          props.data.liveValue !== null ? (
            <span>
              Status is{" "}
              <StatusIcon status={props.data.liveValue as ColorStatusType} />
            </span>
          ) : null
        }
        submittedValue={
          props.formMode === "resubmit" ? (
            cachedSubmittedValue
          ) : props.data.submittedValue !== null ? (
            <span>
              Status is{" "}
              <StatusIcon
                status={props.data.submittedValue as ColorStatusType}
              />
            </span>
          ) : null
        }
        finalValue={
          props.data.finalValue !== null ? (
            <span>
              Status is{" "}
              <StatusIcon status={props.data.finalValue as ColorStatusType} />
            </span>
          ) : null
        }
        mode={props.formMode}
      />
      <SubmissionFieldEditContainer
        label={props.label}
        description="Your selection will replace the submission"
        submissionField={props.data}
        formMode={props.formMode}
        fieldMode={props.fieldMode}
        isRequired={props.isRequired}
      >
        <StatusSelector
          value={value as ColorStatusType}
          onChange={props.onChange}
          isDisabled={props.isDisabled}
        />
      </SubmissionFieldEditContainer>
    </SubmissionFieldContainer>
  );
};

export default Status;
