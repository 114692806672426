import React from "react";
import "./SubmissionFormSubmittedMessage.scss";
import { Button } from "@gitlab-rtsensing/component-library";
import { FormMode } from "components/submission-forms/common/field-container/SubmissionFieldContainer";
import dayjs from "dayjs";

const namespace = "curation-submission-form-submitted-message";

type Props = {
  formMode: FormMode;
  submittedDate: string | null | undefined;
  onEditClick: () => void;
  skipApproval?: boolean;
  workstream?: string;
};

export const SubmissionFormSubmittedMessage = (props: Props) => {
  if (
    !props.submittedDate ||
    props.formMode !== "submitted" ||
    !props.workstream
  ) {
    return <></>;
  }

  const submittedDate = dayjs.utc(props.submittedDate).tz();

  return props.skipApproval ? (
    <div className={namespace}>
      <div className={`${namespace}-time-text`}>
        Your update was submitted on {submittedDate.format("MMM D [at] h:mm A")}
        .
      </div>
      <div className={`${namespace}-generic-text`}>
        Thank you for helping keep the Sensing {props.workstream} stay
        up-to-date.
        <br />
        <br />
        You can revisit this form up till the point it is processed to modify
        your submission. Our admin will be in touch if there are any questions.
        You&apos;re welcome to close this window now.
      </div>
      <Button
        label="Edit Submission"
        type="secondary"
        onClick={props.onEditClick}
      />
    </div>
  ) : (
    <div className={namespace}>
      <div className={`${namespace}-time-text`}>
        Your update was submitted for review on{" "}
        {submittedDate.format("MMM D [at] h:mm A")}.
      </div>
      <div className={`${namespace}-generic-text`}>
        Thank you for helping keep the Sensing {props.workstream} stay
        up-to-date. Your submission will go through a final approval before
        being published.
        <br />
        <br />
        You can revisit this form up till the point it is reviewed to modify
        your submission. Our admin will be in touch if there are any questions.
        You&apos;re welcome to close this window now.
      </div>
      <Button
        label="Edit Submission"
        type="secondary"
        onClick={props.onEditClick}
      />
    </div>
  );
};

export default SubmissionFormSubmittedMessage;
