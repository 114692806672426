import React from "react";
import "./SubmissionFormView.scss";
import { Submission } from "api";
import SubmissionFormHeader from "../common/header/SubmissionFormHeader";
import FieldMapper from "../common/FieldMapper";
import { FormMode } from "../common/field-container/SubmissionFieldContainer";
import { QueryStatus } from "@tanstack/query-core";
import { Loader } from "@gitlab-rtsensing/component-library";

const namespace = "curation-submission-form-review";

type Props = {
  formMode: FormMode;
  submission: Submission | undefined;
  status: QueryStatus;
};

export const SubmissionFormView = (props: Props) => {
  return (
    <div className={namespace}>
      <SubmissionFormHeader
        formMode={props.formMode}
        submission={props.submission}
      />
      <div className={`${namespace}-container`}>
        <Loader status={props.status}>
          {props.submission?.fieldEntries
            .sort((fe1, fe2) => {
              const order1 = props.submission?.form.fields.find(
                f => f.id === fe1.fieldId
              )?.formOrder;

              const order2 = props.submission?.form.fields.find(
                f => f.id === fe2.fieldId
              )?.formOrder;

              if (order1 === undefined || order2 === undefined) {
                return 0;
              }

              return order1 - order2;
            })
            .map((f, i) => (
              <FieldMapper
                key={i}
                fields={props.submission?.form.fields || []}
                field={f}
                index={i}
                onChange={() => {}} // eslint-disable-line @typescript-eslint/no-empty-function
                formMode={props.formMode}
                fieldMode={"readonly"}
                isDisabled={false}
                registerValidation={() => () => {}} // eslint-disable-line @typescript-eslint/no-empty-function
              />
            ))}
        </Loader>
      </div>
    </div>
  );
};

export default SubmissionFormView;
