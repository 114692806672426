import React, { useMemo } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import pages from "./pages";

const AppRoutes = () => {
  const pageRoutes = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getPageRoutes = (page: any): JSX.Element[] => {
      const { path, go, component, ...childrenPages } = page;

      let routes =
        (component && [<Route key={path} path={path} element={component} />]) ||
        [];

      for (const [, value] of Object.entries(childrenPages)) {
        routes = routes.concat(getPageRoutes(value));
      }

      return routes;
    };

    return getPageRoutes(pages);
  }, []);

  return (
    <Routes>
      {pageRoutes}
      <Route
        path="*"
        element={<Navigate to={pages.unauthorized.go()} replace />}
      />
    </Routes>
  );
};

export default AppRoutes;
