import React from "react";

type Props = {
  width?: number;
  height?: number;
  className?: string;
  onClick?: () => void;
};
//default width: 10 height: 12
export const LocationIcon = (props: Props) => {
  const { width = 10, height = 12, className, onClick } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M4.99967 0.166626C3.762 0.166626 2.57501 0.658291 1.69984 1.53346C0.824673 2.40863 0.333008 3.59562 0.333008 4.83329C0.333008 7.98329 4.44551 11.5416 4.62051 11.6933C4.72617 11.7837 4.86064 11.8333 4.99967 11.8333C5.13871 11.8333 5.27318 11.7837 5.37884 11.6933C5.58301 11.5416 9.66634 7.98329 9.66634 4.83329C9.66634 3.59562 9.17468 2.40863 8.29951 1.53346C7.42434 0.658291 6.23735 0.166626 4.99967 0.166626ZM4.99967 10.4625C3.75717 9.29579 1.49967 6.78163 1.49967 4.83329C1.49967 3.90503 1.86842 3.0148 2.5248 2.35842C3.18118 1.70204 4.07142 1.33329 4.99967 1.33329C5.92793 1.33329 6.81817 1.70204 7.47455 2.35842C8.13093 3.0148 8.49967 3.90503 8.49967 4.83329C8.49967 6.78163 6.24217 9.30163 4.99967 10.4625ZM4.99967 2.49996C4.53818 2.49996 4.08706 2.63681 3.70334 2.8932C3.31963 3.14959 3.02056 3.514 2.84396 3.94036C2.66735 4.36673 2.62114 4.83588 2.71118 5.2885C2.80121 5.74113 3.02344 6.15689 3.34976 6.48321C3.67608 6.80953 4.09184 7.03176 4.54446 7.12179C4.99709 7.21182 5.46624 7.16562 5.8926 6.98901C6.31896 6.81241 6.68338 6.51334 6.93977 6.12962C7.19616 5.74591 7.33301 5.29478 7.33301 4.83329C7.33301 4.21445 7.08717 3.62096 6.64959 3.18338C6.21201 2.74579 5.61851 2.49996 4.99967 2.49996ZM4.99967 5.99996C4.76893 5.99996 4.54337 5.93154 4.35151 5.80334C4.15965 5.67515 4.01012 5.49294 3.92181 5.27976C3.83351 5.06658 3.81041 4.832 3.85542 4.60569C3.90044 4.37938 4.01156 4.1715 4.17472 4.00833C4.33788 3.84517 4.54576 3.73406 4.77207 3.68904C4.99838 3.64403 5.23296 3.66713 5.44614 3.75543C5.65932 3.84374 5.84153 3.99327 5.96972 4.18513C6.09792 4.37698 6.16634 4.60255 6.16634 4.83329C6.16634 5.14271 6.04342 5.43946 5.82463 5.65825C5.60584 5.87704 5.30909 5.99996 4.99967 5.99996Z"
        fill="#585858"
      />
    </svg>
  );
};

export default LocationIcon;
