import React from "react";

type Props = {
  fill?: string;
  width?: number;
  height?: number;
  className?: string;
};

export const ExclamationIconAlt = (props: Props) => {
  const { fill = "currentColor", width = 16, height = 16, className } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7.5" cy="8" r="6.5" stroke={fill} />
      <path
        d="M7.52876 4.86201C7.65378 4.73699 7.82335 4.66675 8.00016 4.66675C8.17697 4.66675 8.34654 4.73699 8.47157 4.86201C8.59659 4.98703 8.66683 5.1566 8.66683 5.33341V8.00008C8.66683 8.17689 8.59659 8.34646 8.47157 8.47149C8.34654 8.59651 8.17697 8.66675 8.00016 8.66675C7.82335 8.66675 7.65378 8.59651 7.52876 8.47149C7.40373 8.34646 7.3335 8.17689 7.3335 8.00008V5.33341C7.3335 5.1566 7.40373 4.98703 7.52876 4.86201Z"
        fill={fill}
        style={{ translate: "-0.4px" }}
      />
      <path
        d="M7.62978 10.1124C7.73942 10.0392 7.86831 10.0001 8.00016 10.0001C8.17697 10.0001 8.34654 10.0703 8.47157 10.1953C8.59659 10.3204 8.66683 10.4899 8.66683 10.6667C8.66683 10.7986 8.62773 10.9275 8.55448 11.0371C8.48122 11.1468 8.3771 11.2322 8.25529 11.2827C8.13347 11.3331 7.99942 11.3463 7.8701 11.3206C7.74078 11.2949 7.62199 11.2314 7.52876 11.1382C7.43552 11.0449 7.37203 10.9261 7.34631 10.7968C7.32058 10.6675 7.33378 10.5334 7.38424 10.4116C7.4347 10.2898 7.52015 10.1857 7.62978 10.1124Z"
        fill={fill}
        style={{ translate: "-0.4px" }}
      />
    </svg>
  );
};

export default ExclamationIconAlt;
