import React from "react";
import "./SubmissionFieldGrayBox.scss";
import { FormMode } from "../field-container/SubmissionFieldContainer";

type GrayBoxSection = {
  label: string;
  value: string | null | JSX.Element;
};

export type Props = {
  fieldName: string;
  mode: FormMode;
  submittedValue: string | null | JSX.Element;
  liveValue: string | null | JSX.Element;
  finalValue: string | null | JSX.Element;
};

const namespace = "curation-submission-field-gray-box";

export const SubmissionFieldGrayBox = (props: Props) => {
  if (
    props.liveValue === null &&
    props.submittedValue === null &&
    props.finalValue === null
  ) {
    return <></>;
  }

  let sections: (GrayBoxSection | null)[] = [];

  let current: GrayBoxSection | null = null;
  if (props.liveValue !== null) {
    current = {
      label:
        props.mode === "view"
          ? `prior ${props.fieldName}`
          : `current ${props.fieldName}`,
      value: props.liveValue
    };
  }

  const submission = {
    label: `submission`,
    value: props.submittedValue
  };

  const final = {
    label: `final ${props.fieldName}`,
    value: props.finalValue
  };

  switch (props.mode) {
    case "submit":
      sections = [current];
      break;
    case "resubmit":
    case "submitted":
      sections = [current, submission];
      break;
    case "review":
      sections = [current, submission];
      break;
    case "view":
      sections = [current, submission, final];
      break;
  }

  return (
    <div className={namespace}>
      {sections.map((section, i) => {
        if (!section) {
          return <></>;
        }

        return (
          <div key={i} className={`${namespace}-section`}>
            <div className={`${namespace}-section-label`}>{section.label}</div>
            <div className={`${namespace}-section-value`}>{section.value}</div>
          </div>
        );
      })}
    </div>
  );
};

export default SubmissionFieldGrayBox;
