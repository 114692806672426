import React, { useContext } from "react";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import { PermissionsContext } from "global/permissions";
import "./UserAssignmentsManagePage.scss";
import BusinessOwnersTabNavigation from "components/user-assignments/tab-navigation/UserAssignmentsTabNavigation";
import BusinessOwnersManagement from "components/user-assignments/manage/UserAssignmentsManage";
import { useParams } from "react-router-dom";

const namespace = "curation-user-assignments-manage-page";

export default function BusinessOwnersManagePage(): JSX.Element {
  const perms = useContext(PermissionsContext).businessOwners.manage;
  const { workspaceId = "" } = useParams();

  return (
    <div className={namespace}>
      <PermissionsWrapper permissions={perms}>
        <div className={`${namespace}-content`}>
          <div className={`${namespace}-content-white-container`}>
            <BusinessOwnersTabNavigation workspaceId={workspaceId} />
            <BusinessOwnersManagement workspaceId={workspaceId} />
          </div>
        </div>
      </PermissionsWrapper>
    </div>
  );
}
