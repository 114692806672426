import React from "react";

type PencilProps = {
  height?: number;
  width?: number;
  className?: string;
  fill?: string;
};

export const PencilIcon = (props: PencilProps) => {
  const { width = 12, height = 12, className, fill = "currentColor" } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.8332 3.2233C11.8336 3.14653 11.8189 3.07043 11.7899 2.99936C11.7609 2.92828 11.7181 2.86364 11.664 2.80914L9.19068 0.335802C9.13617 0.281738 9.07153 0.238965 9.00046 0.209936C8.92939 0.180906 8.85329 0.166192 8.77651 0.166636C8.69974 0.166192 8.62364 0.180906 8.55257 0.209936C8.4815 0.238965 8.41685 0.281738 8.36235 0.335802L6.71151 1.98664L0.33568 8.36247C0.281616 8.41698 0.238843 8.48162 0.209814 8.55269C0.180784 8.62376 0.16607 8.69987 0.166514 8.77664V11.25C0.166514 11.4047 0.227972 11.5531 0.337368 11.6624C0.446764 11.7718 0.595137 11.8333 0.749847 11.8333H3.22318C3.3048 11.8377 3.38645 11.825 3.46283 11.7958C3.5392 11.7667 3.6086 11.7218 3.66651 11.6641L10.0073 5.2883L11.664 3.66664C11.7173 3.6101 11.7606 3.54503 11.7923 3.47414C11.798 3.42764 11.798 3.38063 11.7923 3.33414C11.7951 3.30698 11.7951 3.27962 11.7923 3.25247L11.8332 3.2233ZM2.98401 10.6666H1.33318V9.0158L7.12568 3.2233L8.77651 4.87414L2.98401 10.6666ZM9.59901 4.05164L7.94818 2.4008L8.77651 1.5783L10.4215 3.2233L9.59901 4.05164Z"
        fill={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PencilIcon;
