import React from "react";

type Props = {
  fill?: string;
  width?: number;
  height?: number;
  className?: string;
};
//defult size 12,12
export const ChatIcon = (props: Props) => {
  const { width = 18, height = 17, className, fill = "currentColor" } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.1667 4.50742H14.8333C15.7538 4.50742 16.5 5.20073 16.5 6.05596V10.7016C16.5 11.5568 15.7538 12.2501 14.8333 12.2501H13.1667V15.3472L9.83333 12.2501H6.5C6.03976 12.2501 5.6231 12.0768 5.32149 11.7966M5.32149 11.7966L8.16667 9.15304H11.5C12.4205 9.15304 13.1667 8.45974 13.1667 7.6045V2.95888C13.1667 2.10364 12.4205 1.41034 11.5 1.41034H3.16667C2.24619 1.41034 1.5 2.10364 1.5 2.95888V7.6045C1.5 8.45974 2.24619 9.15304 3.16667 9.15304H4.83333V12.2501L5.32149 11.7966Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChatIcon;
