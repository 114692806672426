import React, { useContext, useEffect, useState } from "react";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import { PermissionsContext } from "global/permissions";
import "./SubmissionCancelledPage.scss";
import { useSubmissionLocation } from "global/useSubmissionLocation";
import pages from "pages/pages";
import { useOktaAuth } from "@okta/okta-react";
import { UserClaims } from "@okta/okta-auth-js";
import { useNavigate } from "react-router";
import SubmissionFormCancelledMessage from "components/submission-forms/cancelled/SubmissionFormCancelledMessage";

const namespace = "curation-submission-cancelled-page";

export default function SubmissionCancelledPage(): JSX.Element {
  const perms = useContext(PermissionsContext).submissions.view;
  const { data: submission } = useSubmissionLocation(
    pages.submission.cancelled.path
  );
  const navigate = useNavigate();

  const { oktaAuth } = useOktaAuth();
  const [user, setUser] = useState<UserClaims>();
  useEffect(() => {
    oktaAuth.getUser().then(u => setUser(u));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!submission || !user) {
      return;
    }

    if (submission.status.status === "created") {
      navigate(pages.submission.go(submission.id));
    } else if (submission.status.status === "submitted") {
      if (submission.assignee.email === user.email) {
        navigate(pages.submission.go(submission.id));
      } else {
        navigate(pages.submission.review.go(submission.id));
      }
    } else if (
      submission.status.status === "approved" ||
      submission.status.status === "rejected"
    ) {
      navigate(pages.submission.view.go(submission.id));
    } else if (submission.status.status === "processed") {
      navigate(pages.submission.processed.go(submission.id));
    } else if (!perms && submission.assignee.email !== user.email) {
      // "approved" OR "rejected"
      navigate(pages.unauthorized.go());
    }
  }, [submission, navigate, user, perms]);

  return (
    <div className={namespace}>
      <PermissionsWrapper permissions={perms}>
        <SubmissionFormCancelledMessage submission={submission} />
      </PermissionsWrapper>
    </div>
  );
}
