import React, { useEffect, useState } from "react";
import classNames from "classnames";
import "./StatusToast.scss";
import { CheckIcon, ExclamationIcon } from "icons";
import { MutationStatus } from "@tanstack/react-query";

const namespace = "curation-status-toast";

type Message = {
  text: string;
  icon: JSX.Element;
};

export type Props = {
  show: undefined | MutationStatus;
  messages?: {
    success?: string;
    error?: string;
  };
  className?: string;
};

export const StatusToast = (props: Props): JSX.Element => {
  const [message, setMessage] = useState<Message | undefined>(undefined);

  useEffect(() => {
    if (props.show === "success") {
      setMessage({
        text: props.messages?.success || "Success!",
        icon: <CheckIcon className={`${namespace}-icon-success`} />
      });
    } else if (props.show === "error") {
      setMessage({
        text:
          props.messages?.error ||
          "An unexpected error has occured. Please try again.",
        icon: <ExclamationIcon className={`${namespace}-icon-error`} />
      });
    }
  }, [props.messages?.error, props.messages?.success, props.show]);

  const className = classNames(namespace, props.className, {
    hide: !props.show
  });

  return (
    <div className={className}>
      {message?.icon}
      <div className={`${namespace}-message`}>{message?.text}</div>
    </div>
  );
};

export default StatusToast;
