import { useCallback, useRef } from "react";

export const useSubmissionFormValidation = () => {
  const validationFuncs = useRef(new Map<number, () => string[]>());

  const registerValidation = useCallback(
    (id: number, hasErrors: () => string[]) => {
      validationFuncs.current.set(id, hasErrors);

      return () => {
        validationFuncs.current.delete(id);
      };
    },
    []
  );

  const triggerValidation = useCallback(() => {
    let errors: string[] = [];

    validationFuncs.current.forEach(valFunc => {
      errors = errors.concat(valFunc());
    });

    return errors;
  }, []);

  return {
    registerValidation,
    triggerValidation
  };
};

export default useSubmissionFormValidation;
