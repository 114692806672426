import React from "react";

type Props = {
  height?: number;
  width?: number;
  onClick?: () => void;
  className?: string;
  color?: string;
};

//default to 20
export const CloseIcon = (props: Props) => {
  const {
    width = 20,
    height = 20,
    onClick,
    className,
    color = "currentColor"
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M17.5 17.4556L2.5 2.87341"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 2.87341L2.5 17.4557"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CloseIcon;
