import React from "react";

type Props = {
  fill?: string;
  width?: number;
  height?: number;
  className?: string;
};
//defult size 12,12
export const FlagIcon = (props: Props) => {
  const { width, height, className } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.40625 10.5938V8.55208M1.40625 8.55208V2.42708C1.40625 1.86329 1.86329 1.40625 2.42708 1.40625H5.74479L6.25521 1.91667H10.5938L9.0625 4.97917L10.5938 8.04167H6.25521L5.74479 7.53125H2.42708C1.86329 7.53125 1.40625 7.98829 1.40625 8.55208ZM6 1.66146V4.46875"
        stroke="#2F2F2F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FlagIcon;
