import React from "react";
import "./SubmissionFormSubmitFooterButtons.scss";
import { Submission } from "api";
import { Button } from "@gitlab-rtsensing/component-library";
import { FormMode } from "../../common/field-container/SubmissionFieldContainer";
import { MutationStatus } from "@tanstack/react-query";
import { SubmitMode } from "../radio-buttons/SubmissionFormSubmitRadioButtons";

const namespace = "curation-submission-form-submit-footer-buttons";

type Props = {
  formMode: FormMode;
  setFormMode: React.Dispatch<React.SetStateAction<FormMode>>;
  submitMode: SubmitMode | undefined;
  submission: Submission | undefined;
  onSubmit: (submission: Submission) => void;
  onCancel: () => void;
  status: MutationStatus;
};

export const SubmissionFormSubmitFooterButtons = (props: Props) => {
  if (props.formMode !== "submit" && props.formMode !== "resubmit") {
    return <></>;
  }

  const submitBtnLabel =
    props.formMode === "resubmit" ? "Confirm Edits" : "Submit";

  return (
    <div className={namespace}>
      {props.formMode === "resubmit" && (
        <Button
          label="Cancel"
          type="secondary"
          disabled={props.status === "loading"}
          onClick={props.onCancel}
        />
      )}
      {(props.formMode === "submit" || props.formMode === "resubmit") && (
        <Button
          label={submitBtnLabel}
          type="primary"
          disabled={!props.submitMode || props.status === "loading"}
          onClick={() => {
            if (props.submission) {
              props.onSubmit(props.submission);
            }
          }}
        />
      )}
    </div>
  );
};

export default SubmissionFormSubmitFooterButtons;
