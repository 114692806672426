import React, { createContext, useMemo } from "react";
import axios from "axios";
import { useOktaAuth } from "@okta/okta-react";
import { AuthState, OktaAuth } from "@okta/okta-auth-js";
import { getKeyEvents, KeyEventSeries } from "./getKeyEvents";

type AxiosContextType = {
  authState: AuthState | null;
  oktaAuth: OktaAuth | null;
  getKeyEvents: () => Promise<KeyEventSeries>;
};
export const AxiosContext = createContext<AxiosContextType>(
  {} as AxiosContextType
);

export default function AxiosProvider({
  children
}: React.PropsWithChildren<unknown>) {
  const { oktaAuth, authState } = useOktaAuth();

  const axiosInstance = useMemo(() => {
    const instance = axios.create({
      headers: {
        "Content-Type": "application/json"
      }
    });

    instance.interceptors.request.use(config => {
      const token = oktaAuth.getAccessToken();
      const newConfig = { ...config };

      if (token && config && config.headers) {
        newConfig.headers = { ...config.headers };
        newConfig.headers.Authorization = `Bearer ${token}`;
        if (process.env.NODE_ENV === "development") {
          // eslint-disable-next-line no-console
          // console.log(token);
        }
      }

      return newConfig;
    });

    return instance;
  }, [oktaAuth]);

  const services = {
    authState,
    oktaAuth,
    getKeyEvents: () => getKeyEvents(axiosInstance)
  };

  return (
    <AxiosContext.Provider value={{ ...services }}>
      {children}
    </AxiosContext.Provider>
  );
}
