import React from "react";

type ExternalLinkProps = {
  height?: number;
  width?: number;
  className?: string;
  stroke?: string;
  strokeWidth?: number;
};

export const ExternalLinkIcon = (props: ExternalLinkProps) => {
  const {
    width = 10,
    height = 10,
    className,
    stroke = "currentColor",
    strokeWidth = 1.5
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`1 1 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5 3H3C2.44772 3 2 3.44772 2 4V9C2 9.55228 2.44772 10 3 10H8C8.55228 10 9 9.55228 9 9V7M7 2H10M10 2V5M10 2L5 7"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExternalLinkIcon;
