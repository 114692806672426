import React from "react";
import "./ColorStatusSelector.scss";
import classNames from "classnames";
import { ColorStatus } from "api";
import StatusSelectorButton from "./status-selector-button/ColorStatusSelectorButton";

const namespace = "curation-color-status-selector";

type Props = {
  value: ColorStatus;
  onChange: (val: string) => void;
  isDisabled?: boolean;
  className?: string;
};

export const StatusSelector = (props: Props) => {
  const className = classNames(namespace, props.className);

  return (
    <div className={className}>
      <StatusSelectorButton
        status="green"
        currentVal={props.value}
        onClick={props.onChange}
        isDisabled={props.isDisabled}
      />
      <StatusSelectorButton
        status="red"
        currentVal={props.value}
        onClick={props.onChange}
        isDisabled={props.isDisabled}
      />
      <StatusSelectorButton
        status="yellow"
        currentVal={props.value}
        onClick={props.onChange}
        isDisabled={props.isDisabled}
      />
      <StatusSelectorButton
        status="gray"
        currentVal={props.value}
        onClick={props.onChange}
        isDisabled={props.isDisabled}
      />
    </div>
  );
};

export default StatusSelector;
