import React from "react";

type Props = {
  fill?: string;
  width?: number;
  height?: number;
  className?: string;
};

export const ExclamationIcon = (props: Props) => {
  const { fill = "currentColor", width = 16, height = 16, className } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="1 1 16 16"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 1.5C7.51664 1.5 6.0666 1.93987 4.83323 2.76398C3.59986 3.58809 2.63856 4.75943 2.07091 6.12987C1.50325 7.50032 1.35472 9.00832 1.64411 10.4632C1.9335 11.918 2.64781 13.2544 3.6967 14.3033C4.7456 15.3522 6.08197 16.0665 7.53683 16.3559C8.99168 16.6453 10.4997 16.4968 11.8701 15.9291C13.2406 15.3614 14.4119 14.4001 15.236 13.1668C16.0601 11.9334 16.5 10.4834 16.5 9C16.4977 7.01157 15.7068 5.10524 14.3008 3.69921C12.8948 2.29318 10.9884 1.50227 9 1.5Z"
        fill={fill}
      />
      <path
        d="M8.46967 5.46967C8.61032 5.32902 8.80109 5.25 9 5.25C9.19891 5.25 9.38968 5.32902 9.53033 5.46967C9.67098 5.61032 9.75 5.80109 9.75 6V9C9.75 9.19891 9.67098 9.38968 9.53033 9.53033C9.38968 9.67098 9.19891 9.75 9 9.75C8.80109 9.75 8.61032 9.67098 8.46967 9.53033C8.32902 9.38968 8.25 9.19891 8.25 9V6C8.25 5.80109 8.32902 5.61032 8.46967 5.46967Z"
        fill="white"
      />
      <path
        d="M8.58332 11.3764C8.70666 11.294 8.85166 11.25 9 11.25C9.19891 11.25 9.38968 11.329 9.53033 11.4697C9.67098 11.6103 9.75 11.8011 9.75 12C9.75 12.1483 9.70601 12.2933 9.6236 12.4167C9.54119 12.54 9.42406 12.6361 9.28701 12.6929C9.14997 12.7497 8.99917 12.7645 8.85368 12.7356C8.7082 12.7067 8.57456 12.6352 8.46967 12.5303C8.36478 12.4254 8.29335 12.2918 8.26441 12.1463C8.23547 12.0008 8.25032 11.85 8.30709 11.713C8.36386 11.5759 8.45999 11.4588 8.58332 11.3764Z"
        fill="white"
      />
    </svg>
  );
};

export default ExclamationIcon;
