import React from "react";

type Props = {
  fill?: string;
  width?: number;
  height?: number;
  className?: string;
};

export const CheckIconAlt = (props: Props) => {
  const { fill = "currentColor", width = 16, height = 16, className } = props;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 15 15"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7.5" cy="8" r="6.5" stroke={fill} />
      <path
        d="M6.87505 10.7292C6.78749 10.7293 6.70078 10.7121 6.61989 10.6786C6.539 10.6451 6.46554 10.5959 6.40372 10.5339L4.52872 8.65891C4.40501 8.53363 4.33588 8.3645 4.33643 8.18844C4.33698 8.01237 4.40716 7.84368 4.53166 7.71918C4.65615 7.59468 4.82484 7.52449 5.00091 7.52393C5.17697 7.52337 5.34611 7.59249 5.47139 7.7162L6.87503 9.11985L10.5287 5.4662C10.654 5.34249 10.8231 5.27337 10.9992 5.27393C11.1753 5.27449 11.344 5.34468 11.4684 5.46918C11.5929 5.59368 11.6631 5.76237 11.6637 5.93844C11.6642 6.1145 11.5951 6.28363 11.4714 6.40891L7.34639 10.5339C7.28456 10.5959 7.2111 10.6451 7.13021 10.6786C7.04932 10.7121 6.96261 10.7293 6.87505 10.7292Z"
        fill={fill}
        style={{ translate: "-0.3px" }}
      />
    </svg>
  );
};

export default CheckIconAlt;
