import React from "react";
import { Select } from "antd";

const { Option } = Select;

export type DropdownItem = {
  value: string;
  label: string;
};

type DropdownProps = {
  value?: string | null;
  data: DropdownItem[];
  multiple?: boolean;
  onSelect: (selected: string) => void;
  className?: string;
  size?: "small" | "middle" | "large";
  loading?: boolean;
  placeholder?: string;
  defaultValue?: string;
  disabled?: boolean;
};

export function Dropdown({
  value,
  data,
  multiple = false,
  onSelect,
  className,
  size = "middle",
  loading,
  placeholder = "Select an option",
  defaultValue,
  disabled = false
}: DropdownProps) {
  return (
    <Select
      value={value}
      mode={multiple ? "multiple" : undefined}
      size={size}
      placeholder={loading ? "Loading..." : placeholder}
      onChange={onSelect}
      className={className}
      loading={loading}
      disabled={disabled}
      defaultValue={defaultValue}
    >
      {data.map(item => (
        <Option key={item.value} value={item.value}>
          {item.label}
        </Option>
      ))}
    </Select>
  );
}

export default Dropdown;
