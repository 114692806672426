import React from "react";
import "./DisplayText.scss";
import { SubmissionField } from "api";
import SubmissionFieldContainer, {
  FormMode
} from "../../field-container/SubmissionFieldContainer";
import SubmissionFieldGrayBox from "../../field-gray-box/SubmissionFieldGrayBox";

const namespace = "curation-field-display-text";

type Props = {
  formMode: FormMode;
  label: string;
  data: SubmissionField;
};

const DisplayText = (props: Props) => {
  return (
    <SubmissionFieldContainer
      className={namespace}
      mode={props.formMode}
      fieldName={props.label}
    >
      <SubmissionFieldGrayBox
        fieldName={props.label}
        liveValue={props.data.liveValue}
        submittedValue={null}
        finalValue={null}
        mode={props.formMode}
      />
    </SubmissionFieldContainer>
  );
};

export default DisplayText;
