import {
  Array,
  Boolean,
  Literal,
  Record,
  Static,
  String,
  Union
} from "runtypes";

const BasicTooltipSectionValidator = Record({
  title: String.nullable(),
  rows: Array(
    Record({
      label: String.nullable(),
      text: String.nullable()
    })
  )
});

export type BasicTooltipSection = Static<typeof BasicTooltipSectionValidator>;

export const BasicTooltipValidator = Record({
  sections: Array(BasicTooltipSectionValidator)
});

export type BasicTooltip = Static<typeof BasicTooltipValidator>;

export const ColorStatusValidator = Union(
  Literal("green"),
  Literal("yellow"),
  Literal("red"),
  Literal("gray")
);

export type ColorStatus = Static<typeof ColorStatusValidator>;

export const ExternalLinkValidator = Record({
  label: String,
  href: String,
  isSensingExternal: Boolean.nullable().optional()
});

export type ExternalLink = Static<typeof ExternalLinkValidator>;

export const UserValidator = Record({
  username: String,
  email: String,
  fullName: String
});

export type User = Static<typeof UserValidator>;
