import React from "react";
import "./SubmissionFieldEditContainer.scss";
import {
  FieldMode,
  FormMode
} from "../field-container/SubmissionFieldContainer";
import { SubmissionField } from "api";

export type Props = {
  className?: string;
  label: string;
  description?: string | JSX.Element;
  submissionField: SubmissionField;
  formMode: FormMode;
  fieldMode: FieldMode;
  children: JSX.Element | JSX.Element[];
  isRequired: boolean;
};

const namespace = "curation-submission-field-edit-container";

export const SubmissionFieldEditContainer = (props: Props) => {
  if (props.fieldMode !== "edit") {
    return <></>;
  }

  if (props.formMode === "submit" && props.submissionField.liveValue === null) {
    return <>{props.children}</>;
  }

  return (
    <div className={namespace}>
      <div className={`${namespace}-label`}>
        <span>Edited {props.label}</span>
        {props.isRequired && <span className={`${namespace}-required`}>*</span>}
      </div>
      {props.description && (
        <div className={`${namespace}-description`}>{props.description}</div>
      )}
      {props.children}
    </div>
  );
};

export default SubmissionFieldEditContainer;
