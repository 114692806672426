import React from "react";
import "./SubmissionFormReviewRejectBox.scss";

const namespace = "curation-submission-form-review-reject-box";

export type ReviewMode = "edit" | "accept" | "reject";

type Props = {
  reviewMode: ReviewMode | undefined;
  value: string | null | undefined;
  onChange: (value: string) => void;
};

export const SubmissionFormReviewRejectBox = (props: Props) => {
  if (props.reviewMode !== "reject") {
    return <></>;
  }

  return (
    <div className={namespace}>
      <div className={`${namespace}-label`}>Rejection Reason</div>
      <textarea
        value={props.value || ""}
        placeholder="Cause for rejection"
        onChange={e => props.onChange(e.target.value)}
      />
    </div>
  );
};

export default SubmissionFormReviewRejectBox;
