import React from "react";

type Props = {
  width?: number;
  height?: number;
  className?: string;
  onClick?: () => void;
};
//default to width: 8 and height: 12
export const RightChevron = (props: Props) => {
  const { width = 8, height = 12, className, onClick } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M1.16994 0.999998C0.983691 1.18736 0.87915 1.44081 0.87915 1.705C0.87915 1.96918 0.983692 2.22264 1.16994 2.41L4.70994 6L1.16994 9.54C0.983692 9.72736 0.87915 9.98081 0.87915 10.245C0.87915 10.5092 0.983692 10.7626 1.16994 10.95C1.26291 11.0437 1.37351 11.1181 1.49537 11.1689C1.61723 11.2197 1.74793 11.2458 1.87994 11.2458C2.01195 11.2458 2.14266 11.2197 2.26452 11.1689C2.38638 11.1181 2.49698 11.0437 2.58994 10.95L6.82994 6.71C6.92367 6.61703 6.99806 6.50643 7.04883 6.38457C7.0996 6.26272 7.12574 6.13201 7.12574 6C7.12574 5.86799 7.0996 5.73728 7.04883 5.61542C6.99806 5.49356 6.92367 5.38296 6.82994 5.29L2.58994 0.999998C2.49698 0.90627 2.38638 0.831876 2.26452 0.781107C2.14266 0.730338 2.01195 0.704199 1.87994 0.704199C1.74793 0.704199 1.61722 0.730338 1.49537 0.781107C1.37351 0.831876 1.26291 0.90627 1.16994 0.999998Z"
        fill="#555555"
      />
    </svg>
  );
};

export default RightChevron;
