import axios from "axios";
import { Array, Record, Static, String } from "runtypes";

const AdGroupUserValidator = Record({
  email: String,
  fullName: String
});

export type AdGroupUser = Static<typeof AdGroupUserValidator>;

const AdGroupUsersValidator = Record({
  users: Array(AdGroupUserValidator)
});

export type AdGroupUsers = Static<typeof AdGroupUsersValidator>;

export const getAdGroupUsers = async (
  adGroup: string
): Promise<AdGroupUsers> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.post<AdGroupUsers>(
    `${REACT_APP_DOMAIN}/v1/curation/ad_group_user_list`,
    { adGroup }
  );

  AdGroupUsersValidator.check(result.data);

  return result.data;
};

export const getBusinessOwners = async (): Promise<AdGroupUsers> => {
  const { NODE_ENV } = window.__RUNTIME_CONFIG__;

  const adGroup = ["development", "test"].includes(NODE_ENV)
    ? "rts-pabusinessowners-nonprod"
    : "rts-pabusinessowners";

  return getAdGroupUsers(adGroup);
};
