/* eslint-disable no-console */
import React, { useEffect, useState } from "react";

type Props = {
  children: JSX.Element | JSX.Element[];
};

function RuntimeSettingsWrapper(props: Props) {
  const [loadDone, setLoadDone] = useState(false);

  useEffect(() => {
    let origin = window.location.origin; //used by standalone
    const mfeUrl = performance
      .getEntriesByType("resource")
      .find(r => r.name.includes("amgen-rtsensing-curation"))?.name; //used by mfe

    if (mfeUrl) {
      origin = new URL(mfeUrl).origin;
    }

    const fileUrl = `${origin}/runtime-env.js`;
    const baseErrorMsg = `error loading Curation Runtime Settings from [${fileUrl}] with reason:`;

    fetch(fileUrl)
      .then(response => {
        if (!response.ok) {
          console.error(baseErrorMsg, `response status was ${response.status}`);
        }

        return response.text();
      })
      .then(eval) // eslint-disable-line no-eval
      .then(() => setLoadDone(true))
      .catch(reason => console.error(baseErrorMsg, reason));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!loadDone) {
    return <></>;
  }

  return <>{props.children}</>;
}

export default RuntimeSettingsWrapper;
