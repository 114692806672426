import React, { useMemo } from "react";
import "./LinksInputDisplay.scss";
import { ExternalLink } from "api";
import { ExternalLinkIcon } from "icons";

const namespace = "curation-field-links-input-display";

export type Props = {
  externalLinksStr: string | null;
};

export const LinksInputDisplay = (props: Props): JSX.Element => {
  const externalLinks = useMemo<ExternalLink[]>(() => {
    return (props.externalLinksStr && JSON.parse(props.externalLinksStr)) || [];
  }, [props.externalLinksStr]);

  if (!externalLinks.length) {
    return <>There are currently no links.</>;
  }

  return (
    <div className={namespace}>
      {externalLinks.map((l, i) => (
        <div className={`${namespace}-item`} key={i}>
          <label>
            {l.label} {l.isSensingExternal && <ExternalLinkIcon />}
          </label>
          <a href={l.href} target="_blank" rel="noreferrer">
            {l.href}
          </a>
        </div>
      ))}
    </div>
  );
};

export default LinksInputDisplay;
