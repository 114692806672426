import axios from "axios";
import { Array, Boolean, Number, Record, Static, String } from "runtypes";

const UserAssignmentValidator = Record({
  id: Number,
  externalItemId: String,
  itemName: String,
  userEmail: String.nullable(),
  snapshotJsonUrl: String.nullable(),
  formId: String,
  updateUrl: String.nullable(),
  skipNotification: Boolean
});

export type UserAssignment = Static<typeof UserAssignmentValidator>;

const UserAssignmentsValidator = Record({
  assignments: Array(UserAssignmentValidator)
});

export type UserAssignments = Static<typeof UserAssignmentsValidator>;

const UserAssignmentsResponseValidator = Record({
  message: String
});

export type UserAssignmentsResponse = Static<
  typeof UserAssignmentsResponseValidator
>;

export const getUserAssignments = async (
  workspaceId: string
): Promise<UserAssignments> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<UserAssignments>(
    `${REACT_APP_DOMAIN}/v1/curation/user-assignments?workstream_id=${workspaceId}`
  );

  UserAssignmentsValidator.check(result.data);

  return result.data;
};

export const putUserAssignments = async (
  assignments: UserAssignment[]
): Promise<UserAssignmentsResponse> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.put<UserAssignmentsResponse>(
    `${REACT_APP_DOMAIN}/v1/curation/user-assignments`,
    {
      assignments: assignments
    }
  );

  UserAssignmentsResponseValidator.check(result.data);

  return result.data;
};
