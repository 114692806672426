import React from "react";
import "./SubmissionFormCancelledMessage.scss";
import { Submission } from "api";

const namespace = "curation-submission-form-cancelled-message";

type Props = {
  submission: Submission | undefined;
};

export const SubmissionFormCancelledMessage = (props: Props) => {
  if (!props.submission || props.submission.status.status !== "cancelled") {
    return <></>;
  }

  return (
    <div className={namespace}>
      <div className={`${namespace}-info-text`}>
        This submission request has been {props.submission.status.status}.
      </div>
      <div className={`${namespace}-generic-text`}>
        Please review your Teams messages and check for a new link, if a
        submission is still needed.
      </div>
    </div>
  );
};

export default SubmissionFormCancelledMessage;
