import React, { useMemo } from "react";
import "./UserAssignmentsTabNavigation.scss";
import TabNavigation from "common-components/tab-navigation/TabNavigation";
import pages from "pages/pages";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import { TabNavigationItemType } from "common-components/tab-navigation/tab-navigation-item/TabNavigationItem";

const namespace = "curation-user-assignments-tab-navigation";

type Props = {
  workspaceId: string;
};

export const UserAssignmentsTabNavigation = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isRequest, isManage] = useMemo(() => {
    const isRequest = !!matchRoutes(
      [{ path: pages.userAssignments.request.path }],
      location
    );
    const isManage = !!matchRoutes(
      [{ path: pages.userAssignments.manage.path }],
      location
    );

    return [isRequest, isManage];
  }, [location]);

  const navigationItems: TabNavigationItemType[] = [
    {
      label: "Request Updates",
      isSelected: isRequest,
      onClick: () =>
        navigate(pages.userAssignments.request.go(props.workspaceId))
    },
    {
      label: "Set Business Owners",
      isSelected: isManage,
      onClick: () =>
        navigate(pages.userAssignments.manage.go(props.workspaceId))
    }
  ];

  return <TabNavigation className={namespace} items={navigationItems} />;
};

export default UserAssignmentsTabNavigation;
