import React from "react";
import "./ColorStatusSelectorButton.scss";
import classNames from "classnames";
import { ColorStatus } from "api";
import StatusIcon from "common-components/status-icon/StatusIcon";

const namespace = "curation-color-status-selector-button";

type Props = {
  status: ColorStatus;
  currentVal: ColorStatus;
  onClick: (val: ColorStatus) => void;
  isDisabled: boolean | undefined;
};

export const StatusButton = (props: Props) => {
  const className = classNames(namespace, {
    selected: props.status === props.currentVal,
    isDisabled: props.isDisabled
  });

  return (
    <div
      onClick={() => {
        if (!props.isDisabled) {
          props.onClick(props.status);
        }
      }}
      className={className}
    >
      <StatusIcon status={props.status} />
    </div>
  );
};

export default StatusButton;
