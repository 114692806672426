import React from "react";
import "./error-messages.scss";

const namespace = "curation-field-links-error-messages";

type Props = {
  errors: string[] | undefined;
};

const ErrorMessages = (props: Props) => {
  if (!props.errors?.length) {
    return <></>;
  }

  return (
    <div className={`${namespace}`}>
      {props.errors.map((e, i) => (
        <span key={i}>{e}</span>
      ))}
    </div>
  );
};

export default ErrorMessages;
