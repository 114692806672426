import React from "react";
import "./SubmissionFormHeader.scss";
import { FormMode } from "../field-container/SubmissionFieldContainer";
import { Submission } from "api";
import dayjs from "dayjs";

const namespace = "curation-submission-form-header";

type Props = {
  formMode: FormMode;
  submission: Submission | undefined;
};

export const SubmissionFormHeader = (props: Props) => {
  if (!props.submission) {
    const skeletonClassName = "skeleton";

    return (
      <div className={namespace}>
        <div className={`${namespace}-shimmer-container`}>
          <div className={`${namespace}-container`}>
            <label className={skeletonClassName}>&nbsp;</label>
            <div className={`title ${skeletonClassName}`}>&nbsp;</div>
          </div>
          <div className="row-2">
            <div className={`${namespace}-container`}>
              <label className={skeletonClassName}>&nbsp;</label>
              <div className={`value ${skeletonClassName}`}>&nbsp;</div>
            </div>
            <div className={`${namespace}-container`}>
              <label className={skeletonClassName}>&nbsp;</label>
              <div className={`value ${skeletonClassName}`}>&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  let dateLabel: string;
  let dateValue: string;

  switch (props.formMode) {
    case "submit":
    case "submitted":
    case "resubmit":
    case "view":
      dateLabel = "Requested";
      dateValue = props.submission?.createdDate || "";
      break;
    case "review":
      dateLabel = "Received";
      dateValue = props.submission?.submittedDate || "";
      break;
  }

  const dateObj = dayjs.utc(dateValue).tz();

  return (
    <div className={namespace}>
      <div className={`${namespace}-container`}>
        <label>{props.submission?.form.workstream.workstream}</label>
        <div className="title">{props.submission?.title}</div>
      </div>
      <div className="row-2">
        <div className={`${namespace}-container`}>
          <label>Submission Author</label>
          <div className="value">{props.submission?.assignee.fullName}</div>
        </div>
        <div className={`${namespace}-container`}>
          <label>Date {dateLabel}</label>
          <div className="value">{dateObj.format("D MMM YYYY")}</div>
        </div>
      </div>
    </div>
  );
};

export default SubmissionFormHeader;
