//import axios from "axios";
import { Boolean, Record, Static } from "runtypes";

const AuthorizationValidator = Record({
  curation: Boolean,
  curationAuthorization: Record({
    submissions: Boolean
  })
});

export type Authorization = Static<typeof AuthorizationValidator>;

export const getAuthorization = async (): Promise<Authorization> => {
  // const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  // const result = await axios.get<Authorization>(
  //   `${REACT_APP_DOMAIN}/api/v1/authorization`
  // );

  const result = {
    data: { curation: true, curationAuthorization: { submissions: true } }
  };

  AuthorizationValidator.check(result.data);

  return result.data;
};
