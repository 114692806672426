import React from "react";

type Props = {
  width?: number;
  height?: number;
  className?: string;
  onClick?: () => void;
  fill?: string;
};

export const LeftArrowIcon = (props: Props) => {
  const { width, height, className, onClick, fill } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M4.72209 2.10104C6.28428 1.05722 8.1209 0.500056 9.99972 0.5C12.5184 0.502956 14.9332 1.50482 16.7142 3.28584C18.4952 5.06691 19.4971 7.48175 19.5 10.0006C19.4999 11.8793 18.9427 13.7158 17.899 15.2779C16.8551 16.8402 15.3714 18.0578 13.6355 18.7769C11.8996 19.4959 9.98947 19.684 8.14665 19.3175C6.30383 18.9509 4.61109 18.0461 3.28249 16.7175C1.95389 15.3889 1.0491 13.6962 0.682544 11.8534C0.315985 10.0105 0.504116 8.1004 1.22315 6.36451C1.94218 4.62861 3.15982 3.14491 4.72209 2.10104Z"
        fill="white"
        stroke="#888888"
      />
      <path
        d="M11.9442 15.0915C12.13 14.9041 12.2344 14.6507 12.2344 14.3865C12.2344 14.1223 12.13 13.8689 11.9442 13.6815L8.41138 10.0915L11.9442 6.5515C12.13 6.36414 12.2344 6.11069 12.2344 5.8465C12.2344 5.58232 12.13 5.32886 11.9442 5.1415C11.8514 5.04777 11.741 4.97338 11.6194 4.92261C11.4978 4.87184 11.3674 4.8457 11.2356 4.8457C11.1039 4.8457 10.9734 4.87184 10.8518 4.92261C10.7302 4.97338 10.6198 5.04777 10.5271 5.1415L6.2957 9.3815C6.20216 9.47446 6.12792 9.58507 6.07726 9.70692C6.02659 9.82878 6.00051 9.95949 6.00051 10.0915C6.00051 10.2235 6.02659 10.3542 6.07726 10.4761C6.12792 10.5979 6.20216 10.7085 6.2957 10.8015L10.5271 15.0915C10.6198 15.1852 10.7302 15.2596 10.8518 15.3104C10.9734 15.3612 11.1039 15.3873 11.2356 15.3873C11.3674 15.3873 11.4978 15.3612 11.6194 15.3104C11.741 15.2596 11.8514 15.1852 11.9442 15.0915Z"
        fill={fill || "#555555"}
      />
    </svg>
  );
};

export default LeftArrowIcon;
