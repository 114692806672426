import React from "react";
import TabNavigationItem, {
  TabNavigationItemType
} from "./tab-navigation-item/TabNavigationItem";
import "./TabNavigation.scss";
import classNames from "classnames";

const namespace = "rts-tab-navigation";

type Props = {
  items: TabNavigationItemType[];
  className?: string;
};

export const TabNavigation = (props: Props) => {
  const className = classNames(namespace, props.className);

  return (
    <div className={className}>
      {props.items.map((item, index) => (
        <TabNavigationItem key={index} {...item} />
      ))}
    </div>
  );
};

export default TabNavigation;
