import axios from "axios";
import { Submission, SubmissionValidator } from "./common-submissions";
import { Array, Number, Record, Static, String } from "runtypes";
//import * as TestData from "__fixtures__/submission-form/submission-review.json";

//#region Submission Submit/Approval Flow
export const getSubmission = async (id: string): Promise<Submission> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<Submission>(
    `${REACT_APP_DOMAIN}/v2/curation/submission/${id}`
  );

  result.data.skipNextApproval = false; //It will be sent while submit or publish, doesn't exist in result.data

  SubmissionValidator.check(result.data);

  return result.data;
};

export const submitSubmission = async (
  submission: Submission
): Promise<void> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  await axios.put<Submission>(
    `${REACT_APP_DOMAIN}/v2/curation/submission/${submission.id}/submit`,
    {
      fieldEntries: submission.fieldEntries,
      saveDraft: false,
      skip_next_step: submission.skipNextApproval
    }
  );
};

export const approveSubmission = async (
  submission: Submission,
): Promise<void> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  await axios.put<Submission>(
    `${REACT_APP_DOMAIN}/v2/curation/submission/${submission.id}/approve`,
    {
      fieldEntries: submission.fieldEntries,
      skip_next_step: submission.skipNextApproval
    }
  );
};

export const rejectSubmission = async (
  submission: Submission
): Promise<void> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  await axios.put<Submission>(
    `${REACT_APP_DOMAIN}/v2/curation/submission/${submission.id}/reject`,
    {
      rejectReason: submission.rejectReason
    }
  );
};
//#endregion

//#region Mass Request flow
export type CreateSubmissionRequest = {
  userEmail: string;
  title?: string;
  formId: string;
  metadata?: string;
  snapshotJson?: string;
  updateUrl?: string | null;
  skipNotification?: boolean;
};

export type CreateSubmissionsMassRequestItem = {
  id: number; //can be any arbitrary identifier; used to return orderly errors; BOAsgmtId in Curation
  snapshotJsonUrl: string | null;
  submission: CreateSubmissionRequest;
};

export const CreateSubmissionResponseValidator = Record({
  submissionTitle: String,
  submissionId: String,
  href: String
});

export type CreateSubmissionResponse = Static<
  typeof CreateSubmissionResponseValidator
>;

type CreateSubmissionsMassRequest = {
  submissionRequests: CreateSubmissionsMassRequestItem[];
};

const CreateSubmissionMassResponseValidator = Record({
  submissionResponses: Array(
    Record({
      id: Number,
      details: CreateSubmissionResponseValidator.nullable(),
      errors: Array(String)
    })
  )
});

export type CreateSubmissionMassResponse = Static<
  typeof CreateSubmissionMassResponseValidator
>;

export const createSubmissionsMass = async (
  requestObj: CreateSubmissionsMassRequest
): Promise<CreateSubmissionMassResponse> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.post<CreateSubmissionMassResponse>(
    `${REACT_APP_DOMAIN}/v2/curation/submission/mass-submission`,
    requestObj
  );

  CreateSubmissionMassResponseValidator.check(result.data);

  return result.data;
};

//#endregion
