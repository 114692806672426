import React, {
  FC,
  PropsWithChildren,
  createContext,
  useState,
  useEffect
} from "react";
import { useQuery } from "@tanstack/react-query";
import { Authorization, getAuthorization } from "api";

export type Permissions = {
  isLoaded: boolean;
  businessOwners: {
    manage: boolean;
    request: boolean;
  };
  submissions: {
    view: boolean;
    review: boolean;
  };
};

const initialPermissions: Permissions = {
  isLoaded: true, //false,
  businessOwners: {
    manage: true,
    request: true
  },
  submissions: {
    view: true, //false,
    review: true //false
  }
};

export const PermissionsContext = createContext(initialPermissions);

export const PermissionsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [permissions, setPermissions] = useState(initialPermissions);

  const { status, data } = useQuery<Authorization>(
    ["authorization"],
    getAuthorization
  );

  useEffect(() => {
    if (status === "success") {
      //const auth = data.curationAuthorization;

      const perms: Permissions = {
        isLoaded: true,
        businessOwners: {
          manage: true,
          request: true
        },
        submissions: {
          view: true,
          review: true
        }
      };

      setPermissions(perms);
    }
  }, [status, data]);

  return (
    <PermissionsContext.Provider value={permissions}>
      {children}
    </PermissionsContext.Provider>
  );
};
