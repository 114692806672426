import React from "react";
import "./StatusIcon.scss";
import { ColorStatus } from "api";
import {
  CheckIcon,
  CheckIconAlt,
  CircleIcon,
  ExclamationIcon,
  ExclamationIconAlt,
  MinusIcon,
  MinusIconAlt
} from "icons";
import classNames from "classnames";

export type Props = {
  status: ColorStatus | "inactive" | null;
  className?: string;
  isAlt?: boolean;
};

export const StatusIcon = (props: Props) => {
  if (!props.status) {
    return <></>;
  }

  const className = classNames(
    `curation-status-icon ${props.status.toLowerCase()}`,
    props.className
  );

  switch (props.status.toLowerCase()) {
    case "green":
      return props.isAlt ? (
        <CheckIconAlt className={className} />
      ) : (
        <CheckIcon className={className} />
      );
    case "yellow":
      return props.isAlt ? (
        <MinusIconAlt className={className} />
      ) : (
        <MinusIcon className={className} />
      );
    case "red":
      return props.isAlt ? (
        <ExclamationIconAlt className={className} />
      ) : (
        <ExclamationIcon className={className} />
      );
    case "gray":
    case "inactive":
    default:
      return <CircleIcon className={className} />;
  }
};

export default StatusIcon;
