import React from "react";
import "./error-box.scss";
import { ExclamationIconAlt } from "icons";
import { MutationStatus } from "@tanstack/react-query";

const namespace = "curation-error-box";

type Props = {
  errors: string[] | undefined;
  status?: MutationStatus;
};

export const ErrorBox = (props: Props): JSX.Element => {
  if (!props.errors?.length && props.status !== "error") {
    return <></>;
  }

  const genericMessage = "An unexpected error has occured. Please try again.";
  const errorsMessage = "The following errors occured:";

  return (
    <div className={namespace}>
      <div className={`${namespace}-main-msg`}>
        <ExclamationIconAlt />
        {props.errors?.length === 0 ? (
          <span>{genericMessage}</span>
        ) : (
          <span>{errorsMessage}</span>
        )}
      </div>
      {props.errors?.length !== 0 && (
        <ul className={`${namespace}-list`}>
          {props.errors?.map((e, i) => (
            <li key={i} className={`${namespace}-list-item`}>
              {e}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ErrorBox;
