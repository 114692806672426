import React from "react";

type Props = {
  fill?: string;
  width?: number;
  height?: number;
  className?: string;
};

export const MinusIcon = (props: Props) => {
  const { fill = "currentColor", width = 16, height = 16, className } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="1 1 16 16"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 1.5C7.51664 1.5 6.0666 1.93987 4.83323 2.76398C3.59986 3.58809 2.63856 4.75943 2.07091 6.12987C1.50325 7.50032 1.35472 9.00832 1.64411 10.4632C1.9335 11.918 2.64781 13.2544 3.6967 14.3033C4.7456 15.3522 6.08197 16.0665 7.53683 16.3559C8.99168 16.6453 10.4997 16.4968 11.8701 15.9291C13.2406 15.3614 14.4119 14.4001 15.236 13.1668C16.0601 11.9334 16.5 10.4834 16.5 9C16.4977 7.01157 15.7068 5.10524 14.3008 3.69921C12.8948 2.29318 10.9884 1.50227 9 1.5Z"
        fill={fill}
      />
      <rect x="6" y="8.25" width="6" height="1.5" rx="0.75" fill="white" />
    </svg>
  );
};

export default MinusIcon;
