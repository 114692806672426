import React, { useEffect, useState } from "react";
import classNames from "classnames";
import "./TabNavigationItem.scss";

export type TabNavigationItemType = {
  label: string;
  badge?: number;
  isSelected: boolean;
  onClick: () => void;
};
const namespace = "rts-tab-navigation-item";

export const TabNavigationItem = (props: TabNavigationItemType) => {
  const { badge, label, isSelected, onClick } = props;
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(isSelected);
  }, [isSelected]);

  const validateState = () => {
    if (!isActive) {
      onClick();
    }
  };

  const containerClass = classNames(namespace, { active: isSelected });
  return (
    <div className={containerClass} onClick={validateState}>
      <label className={`${namespace}-label`}>{label}</label>
      {badge ? <label className={`${namespace}-badge`}>{badge}</label> : <></>}
    </div>
  );
};

export default TabNavigationItem;
