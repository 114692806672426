import React, { useContext, useEffect, useState } from "react";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import { PermissionsContext } from "global/permissions";
import "./SubmissionReviewPage.scss";
import SubmissionFormReview from "components/submission-forms/review/SubmissionFormReview";
import { useSubmissionLocation } from "global/useSubmissionLocation";
import pages from "pages/pages";
import { useNavigate } from "react-router";
import { useMutation } from "@tanstack/react-query";
import {
  Submission,
  approveSubmission as approveSubmissionApi,
  rejectSubmission as rejectSubmissionApi
} from "api";
import { ReviewMode } from "components/submission-forms/review/radio-buttons/SubmissionFormReviewRadioButtons";
import { useStatusManager } from "global/use-status-manager";
import { UserClaims } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";

const namespace = "curation-submission-review-page";

export default function SubmissionReviewPage(): JSX.Element {
  const perms = useContext(PermissionsContext).submissions.review;
  const {
    data: submission,
    status: getterStatus,
    refetch
  } = useSubmissionLocation(pages.submission.review.path);
  const navigate = useNavigate();
  const { oktaAuth } = useOktaAuth();
  const [user, setUser] = useState<UserClaims>();
  useEffect(() => {
    oktaAuth.getUser().then(u => setUser(u));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { status: approveStatus, mutate: approveSubmission } = useMutation(
    approveSubmissionApi,
    {
      onMutate: () => {
        overrideStatus("onApprove", "loading");
      },
      onSuccess: () => {
        refetch().then(() => {
          overrideStatus("onApprove", undefined);
        });
      }
    }
  );
  const { status: rejectStatus, mutate: rejectSubmission } = useMutation(
    rejectSubmissionApi,
    {
      onMutate: () => {
        overrideStatus("onReject", "loading");
      },
      onSuccess: () => {
        refetch().then(() => {
          overrideStatus("onReject", undefined);
        });
      }
    }
  );

  const { status, overrideStatus } = useStatusManager(
    getterStatus,
    approveStatus,
    rejectStatus
  );

  const onConfirmClick = (
    reviewMode: ReviewMode,
    newSubmission: Submission,
  ) => {
    if (reviewMode === "reject") {
      rejectSubmission(newSubmission);
    } else if (reviewMode === "accept" || reviewMode === "edit") {
      approveSubmission(newSubmission);
    }
  };

  useEffect(() => {
    if (!submission) {
      return;
    }

    if (submission.status.status === "created") {
      navigate(pages.notfound.go());
    } else if (
      submission.status.status === "approved" ||
      submission.status.status === "rejected"
    ) {
      navigate(pages.submission.view.go(submission.id));
    } else if (submission.status.status === "cancelled") {
      navigate(pages.submission.cancelled.go(submission.id));
    } else if (submission.status.status === "processed") {
      navigate(pages.submission.processed.go(submission.id));
    } else if (
      (submission.status.status === "submitted" ||
        submission.status.status === "in review") &&
      submission.altWorkflow &&
      !submission.altWorkflowStep?.reviewers.some(r => r.email === user?.email)
    ) {

      navigate(pages.submission.pendingReview.go(submission.id));
    } else if (!perms) {
      //submitted
      navigate(pages.unauthorized.go());
    }
  }, [submission, navigate, perms, user]);

  return (
    <div className={namespace}>
      <PermissionsWrapper permissions={perms}>
        <SubmissionFormReview
          formMode="review"
          submission={submission}
          status={status}
          onConfirmClick={onConfirmClick}
          isLastStep={Boolean(submission?.altWorkflowStep?.isFinalStep)}
        />
      </PermissionsWrapper>
    </div>
  );
}
